import { dbRef } from "@/firebaseConfig";
import { apiValidacion } from "../../boot/axios";
import {
  child,
  endAt,
  get,
  getDatabase,
  onValue,
  orderByChild,
  orderByKey,
  query,
  ref,
  remove,
  set,
  startAt,
  update,
} from "firebase/database";
import moment from "moment/moment";
import { v1 as uuidv1 } from "uuid";

const state = {
  tarjeta: null,
  preregistros: null,
  tarjetasVerificadas: null,
  tarjetasVerificadasId: null,
  verificacionesFecha: null,
  campañas: null,
};

// getters
const getters = {};

// mutations
const mutations = {
  setTarjeta(state, nuevatarjeta) {
    state.tarjeta = nuevatarjeta;
  },
  setPreregistros(state, nuevosPreregistros) {
    state.preregistros = nuevosPreregistros;
    console.log(state.preregistros);
  },
  setTarjetasVerificadas(state, nuevasTarjetas) {
    state.tarjetasVerificadas = nuevasTarjetas;
    console.log(state.tarjetasVerificadas);
  },
  setTarjetasVerificadasId(state, nuevasTarjetas) {
    state.tarjetasVerificadasId = nuevasTarjetas;
    console.log(state.tarjetasVerificadasId);
  },
  setverificacionesFecha(state, nuevasVerificaciones) {
    state.verificacionesFecha = nuevasVerificaciones;
  },
  setCampañas(state, nuevasCampañas) {
    state.campañas = nuevasCampañas;
  },
};

// actions
const actions = {
  async buscarTarjeta({ commit }, numTarjeta) {
    commit("setTarjeta", null);
    try {
      const res = await apiValidacion({
        method: "POST",
        url: "/buscar",
        data: {
          tarjeta: numTarjeta,
        },
      });

      if (res.status == 200) {
        console.log(res.data);
        commit("setTarjeta", res.data.data);

        let objetoTarjeta = res.data.data;

        const prefijoTipo = objetoTarjeta.tarjeta.substring(0, 2);
        console.log(prefijoTipo);

        switch (prefijoTipo) {
          case "71":
            objetoTarjeta.template = 4934261;
            objetoTarjeta.nombreSender = "Super Kompras";
            objetoTarjeta.titulo = "Código de verificación Super Kompras";
            break;
          case "77":
            objetoTarjeta.template = 4975552;
            objetoTarjeta.nombreSender = "Acrópolis";
            objetoTarjeta.titulo = "Código de verificación Acrópolis";
            break;
          case "75":
            objetoTarjeta.template = 4975555;
            objetoTarjeta.nombreSender = "Surtitienda";
            objetoTarjeta.titulo = "Código de verificación Surtitienda";
            break;
          case "60":
            objetoTarjeta.template = 4934261;
            objetoTarjeta.nombreSender = "Tarjeta de regalo Super Kompras";
            objetoTarjeta.titulo = "Código de verificación Surtitienda";
            break;
          case "80":
            objetoTarjeta.template = 4975552;
            objetoTarjeta.nombreSender = "Tarjeta de regalo Acrópolis";
            objetoTarjeta.titulo = "Código de verificación Acrópolis";
            break;
        }
        objetoTarjeta.correoSender = "appsk@tiendasgarces.com";

        console.log(objetoTarjeta);

        localStorage.setItem("tarjeta", JSON.stringify(objetoTarjeta));
        return true;
      }
    } catch (error) {
      console.log(error);
      let msgError = {};
      if (error.message == "Network Error") {
        msgError = { msg: "Error de red" };
      } else if (error.response) {
        msgError = error.response.data.errors[0];
      } else {
        msgError = { msg: "Error inesperado, intentelo más tarde" };
      }
      throw msgError;
    }
  },
  async buscarTarjetaCliente({ commit }, numTarjeta) {
    this.tarjeta = null;
    try {
      const res = await apiValidacion({
        method: "POST",
        url: "/cliente/buscar",
        data: {
          tarjeta: numTarjeta,
        },
      });

      if (res.status == 200) {
        console.log(res.data);
        commit("setTarjeta", res.data.data);

        let objetoTarjeta = res.data.data;

        const prefijoTipo = objetoTarjeta.tarjeta.substring(0, 2);
        console.log(prefijoTipo);

        switch (prefijoTipo) {
          case "71":
            objetoTarjeta.template = 4934261;
            objetoTarjeta.nombreSender = "Super Kompras";
            objetoTarjeta.titulo = "Código de verificación Super Kompras";
            break;
          case "77":
            objetoTarjeta.template = 4975552;
            objetoTarjeta.nombreSender = "Acrópolis";
            objetoTarjeta.titulo = "Código de verificación Acrópolis";
            break;
          case "75":
            objetoTarjeta.template = 4975555;
            objetoTarjeta.nombreSender = "Surtitienda";
            objetoTarjeta.titulo = "Código de verificación Surtitienda";
            break;
          case "60":
            objetoTarjeta.template = 4934261;
            objetoTarjeta.nombreSender = "Tarjeta de regalo Super Kompras";
            objetoTarjeta.titulo = "Código de verificación Surtitienda";
            break;
          case "80":
            objetoTarjeta.template = 4975552;
            objetoTarjeta.nombreSender = "Tarjeta de regalo Acrópolis";
            objetoTarjeta.titulo = "Código de verificación Acrópolis";
            break;
        }
        objetoTarjeta.correoSender = "appsk@tiendasgarces.com";
        objetoTarjeta.cliente = "true";

        console.log(objetoTarjeta);

        localStorage.setItem("tarjeta", JSON.stringify(objetoTarjeta));
        return true;
      }
    } catch (error) {
      console.log(error);
      console.log(error.message);
      let msgError = {};
      if (error.message == "Network Error") {
        msgError = { msg: "Error de red" };
      } else if (error.response.data.errors) {
        msgError = error.response.data.errors[0];
      } else if (
        error.response.data &&
        error.message == "Request failed with status code 429"
      ) {
        msgError = { msg: error.response.data };
      } else {
        msgError = "Error inesperado, intentelo más tarde";
      }
      throw msgError;
    }
  },

  async enviarCodigoTelefono({ commit }, objetoCodigoTelefono) {
    try {
      const { telefono, metodo } = objetoCodigoTelefono;
      const res = await apiValidacion({
        method: "POST",
        url: "/enviar-codigo-telefono",
        data: {
          telefono: telefono,
          metodo: metodo,
        },
      });

      console.log(res);
      if (res.status == 200) {
        return true;
      }
    } catch (error) {
      let msgError = {};
      if (error.message == "Network Error") {
        msgError = { msg: "Error de red" };
      } else if (error.response) {
        msgError = error.response.data.errors[0];
      } else {
        msgError = { msg: "Error inesperado, intentelo más tarde" };
      }
      throw msgError;
    }
  },

  async validarCodigoTelefono({ commit }, datosValidacionTel) {
    try {
      const { telefono, codigo } = datosValidacionTel;
      console.log(telefono);
      console.log(codigo);
      const res = await apiValidacion({
        method: "POST",
        url: "/validar-codigo-telefono",
        data: {
          telefono,
          codigo,
        },
      });

      console.log(res);
      if (res.status == 200) {
        return true;
      }
    } catch (error) {
      let msgError = {};
      if (error.message == "Network Error") {
        msgError = { msg: "Error de red" };
      } else if (error.response) {
        msgError = error.response.data.errors[0];
      } else {
        msgError = { msg: "Error inesperado, intentelo más tarde" };
      }
      throw msgError;
    }
  },
  async enviarCodigoCorreo({ commit }, datosEnvioCorreo) {
    try {
      console.log(datosEnvioCorreo);
      const { correo, nombre, template, correoSender, nombreSender, titulo } =
        datosEnvioCorreo;
      const res = await apiValidacion({
        method: "POST",
        url: "/enviar-codigo-correo",
        data: {
          correo,
          nombre,
          template,
          correoSender,
          nombreSender,
          titulo,
        },
      });
      console.log(res);
      if (res.status == 200) {
        return res.data.data.databaseIdentifier;
      }
    } catch (error) {
      let msgError = {};
      if (error.message == "Network Error") {
        msgError = { msg: "Error de red" };
      } else if (error.response) {
        msgError = error.response.data.errors[0];
      } else {
        msgError = { msg: "Error inesperado, intentelo más tarde" };
      }
      throw msgError;
    }
  },
  async validarCodigoCorreo({ commit }, datosVerificacionCorreo) {
    try {
      const { dbIdentificador, correo, codigo } = datosVerificacionCorreo;
      const res = await apiValidacion({
        method: "POST",
        url: "/validar-codigo-correo",
        data: {
          dbIdentificador,
          correo,
          codigo,
        },
      });
      console.log(res);
      if (res.status == 200) {
        return true;
      }
    } catch (error) {
      let msgError = {};
      if (error.message == "Network Error") {
        msgError = { msg: "Error de red" };
      } else if (error.response) {
        msgError = error.response.data.errors[0];
      } else {
        msgError = { msg: "Error inesperado, intentelo más tarde" };
      }
      throw msgError;
    }
  },
  async guardarDatosValidadosTarjetahabiente({ commit }, dataTarjetahabiente) {
    try {
      const res = await apiValidacion({
        method: "POST",
        url: "/guardar-tarjetahabiente-verificado",
        data: {
          dataTarjetahabiente,
        },
      });
      console.log(res);

      return true;
    } catch (error) {
      let msgError = {};
      if (error.message == "Network Error") {
        msgError = { msg: "Error de red" };
      } else if (error.response) {
        msgError = error.response.data.errors[0];
      } else {
        msgError = { msg: "Error inesperado, intentelo más tarde" };
      }
      throw msgError;
    }
  },
  async guardarDatosTarjetahabienteHasura(
    { commit },
    dataTarjetahabienteHasura
  ) {
    try {
      const {
        Record_Id,
        apellidoM,
        apellidoP,
        dioalta,
        division,
        email,
        fechaalta,
        fechanacim,
        fechaverfi,
        nombre,
        promotor,
        saldoc,
        sexo,
        tarjeta,
        telefono,
        verificoemail,
        cp,
        actcte,
        id_cli,
        preregistro,
        recomendo,
      } = dataTarjetahabienteHasura;
      console.log(dataTarjetahabienteHasura);
      const res = await apiValidacion({
        method: "POST",
        url: "/guardar-tarjetahabiente-verificado-hasura-v2",
        data: {
          Record_Id,
          apellidoM,
          apellidoP,
          dioalta,
          division,
          email,
          fechaalta,
          fechanacim,
          fechaverfi,
          nombre,
          promotor,
          saldoc,
          sexo,
          tarjeta,
          telefono,
          verificoemail,
          cp,
          actcte,
          id_cli,
          preregistro,
          recomendo,
        },
      });
      console.log(res);

      return true;
    } catch (error) {
      let msgError = {};
      if (error.message == "Network Error") {
        msgError = { msg: "Error de red" };
      } else if (error.response) {
        msgError = error.response.data.errors[0];
      } else {
        msgError = { msg: "Error inesperado, intentelo más tarde" };
      }
      throw msgError;
    }
  },

  async buscarTarjetahabienteFirebase({ commit }, id) {
    try {
      console.log("Buscando tarjetahabiente");
      const snapshot = await get(
        child(
          dbRef,
          `projects/proj_tCJWQHSHNf7WoMu7r64pUJ/data/tarjetahabientesVerificados/${id}`
        )
      );
      console.log(snapshot);
      if (snapshot.exists()) {
        const tarjetahabienteEncontrado = snapshot.val();
        return tarjetahabienteEncontrado;
      } else {
        return false;
      }
    } catch (error) {
      let msgError = {};
      if (error.message == "Network Error") {
        msgError = { msg: "Error de red" };
      } else if (error.response) {
        msgError = error.response.data.errors[0];
      } else {
        msgError = { msg: "Error inesperado, intentelo más tarde" };
      }
      throw msgError;
    }
  },

  async consultarConteoCli({ commit }) {
    try {
      const res = await apiValidacion({
        method: "GET",
        url: "/obtener-conteo-cli-hasura",
      });
      console.log(res.data?.data.cli_gen_aggregate.aggregate.max.id_cli);
      return res.data?.data.cli_gen_aggregate.aggregate.max.id_cli;
    } catch (error) {
      let msgError = {};
      if (error.message == "Network Error") {
        msgError = { msg: "Error de red" };
      } else if (error.response) {
        msgError = error.response.data.errors[0];
      } else {
        msgError = { msg: "Error inesperado, intentelo más tarde" };
      }
      throw msgError;
    }
  },

  async consultarIdCli({ commit }, objetoNombreTarjethabiante) {
    const { nombre, apellidoPaterno, apellidoMaterno } =
      objetoNombreTarjethabiante;
    try {
      const res = await apiValidacion({
        method: "POST",
        url: "/obtener-id-cli-hasura",
        data: {
          nombre,
          apellidoPaterno,
          apellidoMaterno,
        },
      });
      console.log(res.data?.data.cli_gen[0].id_cli);
      return res.data?.data.cli_gen[0].id_cli;
    } catch (error) {
      let msgError = {};
      if (error.message == "Network Error") {
        msgError = { msg: "Error de red" };
      } else if (error.response) {
        msgError = error.response.data.errors[0];
      } else {
        msgError = { msg: "Error inesperado, intentelo más tarde" };
      }
      throw msgError;
    }
  },

  async actualizarDatosTarjetahabienteHasura(
    { commit },
    dataTarjetahabienteHasura
  ) {
    try {
      const {
        Record_Id,
        apellidoM,
        apellidoP,
        dioalta,
        division,
        email,
        fechaalta,
        fechanacim,
        fechaverfi,
        nombre,
        promotor,
        saldoc,
        sexo,
        tarjeta,
        telefono,
        verificoemail,
        cp,
        actcte,
        id_cli,
        preregistro,
        recomendo,
      } = dataTarjetahabienteHasura;
      console.log(dataTarjetahabienteHasura);
      const res = await apiValidacion({
        method: "POST",
        url: "/actualizar-tarjetahabiente-verificado-hasura-v2",
        data: {
          Record_Id,
          apellidoM,
          apellidoP,
          dioalta,
          division,
          email,
          fechaalta,
          fechanacim,
          fechaverfi,
          nombre,
          promotor,
          saldoc,
          sexo,
          tarjeta,
          telefono,
          verificoemail,
          cp,
          actcte,
          id_cli,
          preregistro,
          recomendo,
        },
      });
      console.log(res);

      return true;
    } catch (error) {
      let msgError = {};
      if (error.message == "Network Error") {
        msgError = { msg: "Error de red" };
      } else if (error.response) {
        msgError = error.response.data.errors[0];
      } else {
        msgError = { msg: "Error inesperado, intentelo más tarde" };
      }
      throw msgError;
    }
  },

  async consultarTarjetahabienteHasura({ commit }, idTarjethabiente) {
    console.log(idTarjethabiente);
    try {
      const res = await apiValidacion({
        method: "POST",
        url: "/obtener-un-tarjetahabiente-hasura-v2",
        data: {
          Record_Id: idTarjethabiente,
        },
      });
      return res.data?.data.tarjetahabientesVerificados_by_pk;
    } catch (error) {
      console.log(error);
      let msgError = {};
      if (error.message == "Network Error") {
        msgError = { msg: "Error de red" };
      } else if (error.response) {
        msgError = error.response.data.errors[0];
      } else {
        msgError = { msg: "Error inesperado, intentelo más tarde" };
      }
      throw msgError;
    }
  },

  async guardarPreregistroTarjeta({ commit }, objetoDataPreregistro) {
    const {
      preregistroId,
      apellidoMaterno,
      apellidoPaterno,
      cp,
      email,
      fechaPreregistro,
      fechanacim,
      folio,
      nombre,
      sexo,
      sucursal,
      telefono,
      verificoEmail,
    } = objetoDataPreregistro;
    try {
      const db = getDatabase();
      set(
        ref(
          db,
          "/projects/proj_tCJWQHSHNf7WoMu7r64pUJ/data/preregistroTarjetahabientes/" +
            preregistroId
        ),
        {
          apellidoMaterno,
          apellidoPaterno,
          cp,
          email,
          fechaPreregistro,
          fechanacim,
          folio,
          nombre,
          sexo,
          sucursal,
          telefono,
          verificoEmail,
        }
      );
    } catch (error) {
      throw {
        msg: "Error al guardar el pre registro en firebase",
        code: "SKE-113",
      };
    }
  },

  async consultarTarjetahabientes({ commit }, fechasFiltrado) {
    try {
      const dbRef = getDatabase();
      const pedidosRef = ref(
        dbRef,
        "/projects/proj_tCJWQHSHNf7WoMu7r64pUJ/data/preregistroTarjetahabientes"
      );

      const offset6Horas = 6 * 3600000;
      const offsetDia = 86400000;

      console.log(fechasFiltrado);
      const fechaInicio =
        parseInt(moment(fechasFiltrado[0]).format("x")) -
        offsetDia +
        offset6Horas;
      const fechaFin =
        parseInt(moment(fechasFiltrado[1]).format("x")) + offset6Horas;

      console.log(fechaInicio);
      console.log(fechaFin);

      // const horaActual = new Date();
      // const fechaActual = new Date(('0' + (horaActual.getMonth() + 1)).slice(-2) + "-" + ('0' + horaActual.getDate()).slice(-2) + "-" + horaActual.getFullYear());
      // let fechaActualTimestamp = fechaActual.getTime();
      // const fecha7diasAnteriores = fechaActualTimestamp - 604800000;

      // fechaActualTimestamp += 39600000 + 3540000;

      const queryPedidosRef = query(
        pedidosRef,
        orderByKey(),
        startAt(fechaInicio.toString()),
        endAt(fechaFin.toString())
      );

      await onValue(queryPedidosRef, (snapshot) => {
        console.log(snapshot);
        const data = snapshot.val();

        commit("setPreregistros", data);
        console.log(data);
      });
    } catch (error) {
      console.log(error);
    }
  },

  async consultarTarjetahabientesVerificados({ commit }, fechasFiltrado) {
    try {
      const dbRef = getDatabase();
      const pedidosRef = ref(
        dbRef,
        "/projects/proj_tCJWQHSHNf7WoMu7r64pUJ/data/tarjetahabientesVerificados"
      );

      const offset6Horas = 6 * 3600000;
      const offsetDia = 86400000;

      console.log(fechasFiltrado);
      // const fechaInicio = fechasFiltrado[0];

      // const fechaFin = fechasFiltrado[1];

      const fechaInicioTimestamp =
        parseInt(moment(fechasFiltrado[0]).format("x")) -
        offsetDia +
        offset6Horas;
      const fechaFinTimestamp =
        parseInt(moment(fechasFiltrado[1]).format("x")) + offset6Horas;

      const fechaInicio = moment(fechaInicioTimestamp).format("YYYY-MM-DD");
      const fechaFin = moment(fechaFinTimestamp).format("YYYY-MM-DD");

      console.log(fechaInicio);
      console.log(fechaFin);

      // const horaActual = new Date();
      // const fechaActual = new Date(('0' + (horaActual.getMonth() + 1)).slice(-2) + "-" + ('0' + horaActual.getDate()).slice(-2) + "-" + horaActual.getFullYear());
      // let fechaActualTimestamp = fechaActual.getTime();
      // const fecha7diasAnteriores = fechaActualTimestamp - 604800000;

      // fechaActualTimestamp += 39600000 + 3540000;

      const queryPedidosRef = query(
        pedidosRef,
        orderByChild("fechaverif"),
        startAt(fechaInicio),
        endAt(fechaFin)
      );

      await onValue(queryPedidosRef, (snapshot) => {
        console.log(snapshot);
        const data = snapshot.val();

        commit("setTarjetasVerificadas", data);
        console.log(data);
      });
    } catch (error) {
      console.log(error);
    }
  },

  async borrarPreregistro({ commit }, idPreregistro) {
    try {
      const dbRef = getDatabase();
      const preregistroRef = ref(
        dbRef,
        `/projects/proj_tCJWQHSHNf7WoMu7r64pUJ/data/preregistroTarjetahabientes/${idPreregistro}`
      );

      await remove(preregistroRef);
    } catch (error) {
      console.log(error);
    }
  },

  async enviarFolioPreregistro({ commit }, datosEnvioFolio) {
    try {
      console.log(datosEnvioFolio);
      const { templateID, email, name, folio, senderName } = datosEnvioFolio;
      const res = await apiValidacion({
        method: "POST",
        url: "/enviar-folio-preregistro",
        data: {
          templateID,
          email,
          name,
          folio,
          senderName,
        },
      });
      console.log(res);
      if (res.status == 200) {
        return res.data.data.folio;
      }
    } catch (error) {
      console.log(error);
      let msgError = {};
      if (error.message == "Network Error") {
        msgError = { msg: "Error de red" };
      } else if (error.response) {
        msgError = error.response.data.errors[0];
      } else {
        msgError = { msg: "Error inesperado, intentelo más tarde" };
      }
      throw msgError;
    }
  },

  async consultarEmpleados({ commit }, sucursal) {
    try {
      const res = await apiValidacion({
        method: "POST",
        url: "/obtener-empleados-sucursal",
        data: {
          sucursal,
        },
      });
      console.log(res.data?.data.tbl_users);

      return res.data?.data.tbl_users;
    } catch (error) {
      console.log(error);
      let msgError = {};
      if (error.message == "Network Error") {
        msgError = { msg: "Error de red" };
      } else if (error.response) {
        msgError = error.response.data.errors[0];
      } else {
        msgError = { msg: "Error inesperado, intentelo más tarde" };
      }
      throw msgError;
    }
  },

  async consultarUsuariosEmpleados({ commit }, sucursal) {
    try {
      const res = await apiValidacion({
        method: "POST",
        url: "/obtener-empleados-usuario-sucursal",
        data: {
          sucursal,
        },
      });
      console.log(res.data?.data.tbl_users);

      return res.data?.data.tbl_users;
    } catch (error) {
      console.log(error);
      let msgError = {};
      if (error.message == "Network Error") {
        msgError = { msg: "Error de red" };
      } else if (error.response) {
        msgError = error.response.data.errors[0];
      } else {
        msgError = { msg: "Error inesperado, intentelo más tarde" };
      }
      throw msgError;
    }
  },

  async obtenerTarjethabientesFechas({ commit }, arregloFechas) {
    try {
      const offset6Horas = 6 * 3600000;
      const offsetDia = 86400000;
      const fechaInicioTimestamp =
        parseInt(moment(arregloFechas[0]).format("x")) -
        offsetDia +
        offset6Horas;
      const fechaFinTimestamp =
        parseInt(moment(arregloFechas[1]).format("x")) + offset6Horas;

      const fechaInicio = moment(fechaInicioTimestamp).format("YYYY-MM-DD");
      const fechaFin = moment(fechaFinTimestamp).format("YYYY-MM-DD");

      const res = await apiValidacion({
        method: "POST",
        url: "/obtener-tarjetahabientes-fecha",
        data: {
          fechaInicio: fechaInicio,
          fechaFin: fechaFin,
        },
      });
      console.log(res.data?.data.tarjetahabientesVerificados);

      commit(
        "setverificacionesFecha",
        res.data?.data.tarjetahabientesVerificados
      );
    } catch (error) {
      console.log(error);
      let msgError = {};
      if (error.message == "Network Error") {
        msgError = { msg: "Error de red" };
      } else if (error.response) {
        msgError = error.response.data.errors[0];
      } else {
        msgError = { msg: "Error inesperado, intentelo más tarde" };
      }
      throw msgError;
    }
  },

  async consultarTarjetaActivacion({ commit }, dataConsultaActivacion) {
    try {
      const { tarjeta, fechaActual } = dataConsultaActivacion;
      console.log(fechaActual);
      const res = await apiValidacion({
        method: "POST",
        url: "/obtener-tarjeta-activacion",
        data: {
          tarjeta: tarjeta,
          fechaActual: fechaActual,
        },
      });
      console.log(res.data?.data.PreactivacionTarjetas);

      return res.data?.data.PreactivacionTarjetas;
    } catch (error) {
      console.log(error);
      let msgError = {};
      if (error.message == "Network Error") {
        msgError = { msg: "Error de red" };
      } else if (error.response) {
        msgError = error.response.data.errors[0];
      } else {
        msgError = { msg: "Error inesperado, intentelo más tarde" };
      }
      throw msgError;
    }
  },

  async activarTarjeta({ commit }, objetoActivacion) {
    try {
      const { fecha_activacion, tarjeta } = objetoActivacion;
      const res = await apiValidacion({
        method: "POST",
        url: "/activar-tarjeta",
        data: {
          fecha_activacion: fecha_activacion,
          tarjeta: tarjeta,
        },
      });
      console.log(res.data?.data);

      return res.data?.data;
    } catch (error) {
      console.log(error);
      let msgError = {};
      if (error.message == "Network Error") {
        msgError = { msg: "Error de red" };
      } else if (error.response) {
        msgError = error.response.data.errors[0];
      } else {
        msgError = { msg: "Error inesperado, intentelo más tarde" };
      }
      throw msgError;
    }
  },

  async obtenerCampañasQrs({ commit }) {
    try {
      const snapshot = await get(
        child(
          dbRef,
          `projects/proj_tCJWQHSHNf7WoMu7r64pUJ/data/CampaniasTarjetaClub`
        )
      );
      console.log(snapshot);
      if (snapshot.exists()) {
        const campañas = snapshot.val();
        console.log(campañas);
        commit("setCampañas", campañas);
      }
    } catch (error) {
      throw {
        msg: "Error al consultar campañas QR's",
        code: "SKE-113",
      };
    }
  },

  async guardarFoliosQrs({ commit }, foliosQr) {
    try {
      console.log(foliosQr);
      const db = getDatabase();
      const updates = {};
      const urlBase = "projects/proj_tCJWQHSHNf7WoMu7r64pUJ/data/QR-test";

      foliosQr.map((folioQr) => {
        updates[`${urlBase}/${folioQr.folio}`] = {
          folio: folioQr.folio,
          url: folioQr.url,
        };
      });

      console.log(updates);

      const res = await update(ref(db), updates);
      console.log("Hola");
      // console.log(res);
    } catch (error) {
      console.log(error);
    }
  },

  async consultarTarjetasPreregistro({ commit }, fechasPreregistros) {
    try {
      const { fechaInicio, fechaFin } = fechasPreregistros;
      const fechaInicioFormato = moment(fechaInicio).format("YYYY-MM-DD");
      const fechaFinFormato = moment(fechaFin).format("YYYY-MM-DD");
      console.log(fechaInicioFormato);
      console.log(fechaFinFormato);
      const res = await apiValidacion({
        method: "POST",
        url: "/obtener-tarjetas-preactivacion",
        data: {
          fechaInicio: fechaInicioFormato,
          fechaFin: fechaFinFormato,
        },
      });
      console.log(res.data?.data);

      return res.data?.data.PreactivacionTarjetas;
    } catch (error) {
      console.log(error);
      let msgError = {};
      if (error.message == "Network Error") {
        msgError = { msg: "Error de red" };
      } else if (error.response) {
        msgError = error.response.data.errors[0];
      } else {
        msgError = { msg: "Error inesperado, intentelo más tarde" };
      }
      throw msgError;
    }
  },

  async obtenerUrlImagenQr({ commit }, folio) {
    try {
      const snapshot = await get(
        child(
          dbRef,
          `projects/proj_tCJWQHSHNf7WoMu7r64pUJ/data/QR-test/${folio}/url`
        )
      );

      if (snapshot.exists()) {
        const url = snapshot.val();

        return url;
      } else {
        return "";
      }
    } catch (error) {
      throw {
        msg: "Error al consultar url del QR",
        code: "SKE-113",
      };
    }
  },
  async enviarCorreoMailjet({ commit }, datosEnvioCorreo) {
    try {
      console.log(datosEnvioCorreo);
      const {
        destinatario,
        nombreDestinatario,
        asunto,
        nombreRemitente,
        remitente,
        template,
        variables,
      } = datosEnvioCorreo;
      const res = await apiValidacion({
        method: "POST",
        url: "/enviar-correo-mailjet",
        data: {
          destinatario,
          nombreDestinatario,
          asunto,
          nombreRemitente,
          remitente,
          template,
          variables,
        },
      });
      console.log(res);
      if (res.status == 200) {
        return res.data;
      }
    } catch (error) {
      console.log(error);
      let msgError = {};
      if (error.message == "Network Error") {
        msgError = { msg: "Error de red" };
      } else if (error.response) {
        msgError = error.response.data.errors[0];
      } else {
        msgError = { msg: "Error inesperado, intentelo más tarde" };
      }
      throw msgError;
    }
  },
  async generarCodigoBD({ dispatch }, email) {
    try {
      const timestampSolicitud = moment().format("x");
      const vigencia = parseInt(timestampSolicitud) + 86400000;

      console.log(timestampSolicitud);
      console.log(vigencia);

      const max = 999999;
      const min = 100000;

      const minCeiled = Math.ceil(min);
      const maxFloored = Math.floor(max);

      const codigoVerificacion = Math.floor(
        Math.random() * (maxFloored - minCeiled + 1) + minCeiled
      );
      console.log(codigoVerificacion);
      const uuid = uuidv1();
      console.log(uuid);

      await dispatch("guardarCodigoVerificacion", {
        uuid: uuid,
        codigo: codigoVerificacion,
        email: email,
        fechaSolicitud: timestampSolicitud,
        vigencia: vigencia,
      });

      const tiempoExpiracion = moment(vigencia).format("DD-MM-YYYY HH:mm");
      console.log(tiempoExpiracion);

      return {
        codigo: codigoVerificacion,
        tiempoExpiracion: tiempoExpiracion,
        identficador: uuid,
      };
    } catch (error) {
      throw error;
    }
  },

  async guardarCodigoVerificacion({ commit }, dataCodigoVerificacion) {
    const { uuid, codigo, email, fechaSolicitud, vigencia } =
      dataCodigoVerificacion;
    try {
      const db = getDatabase();
      await set(
        ref(
          db,
          "/projects/proj_tCJWQHSHNf7WoMu7r64pUJ/data/verificacionEmail/" + uuid
        ),
        {
          codigo,
          email,
          fechaSolicitud,
          vigencia,
        }
      );
    } catch (error) {
      console.log(error);
      throw {
        msg: "Error al generar codigo de verifiación",
        code: "SKE-126",
      };
    }
  },
  async consultarCodigoCorreo({ commit }, identificadorDB) {
    try {
      console.log("Buscando tarjetahabiente");
      const snapshot = await get(
        child(
          dbRef,
          "/projects/proj_tCJWQHSHNf7WoMu7r64pUJ/data/verificacionEmail/" +
            identificadorDB
        )
      );
      console.log(snapshot);
      if (snapshot.exists()) {
        const codigoVerificacion = snapshot.val();
        return codigoVerificacion;
      } else {
        return false;
      }
    } catch (error) {
      throw {
        msg: "Error al verificar código ",
        code: "SKE-127",
      };
    }
  },
  async verificarCodigoCorreo({ dispatch }, objetoVerificacionCodigo) {
    try {
      const { identificadorDB, codigoUsuario } = objetoVerificacionCodigo;
      const timestampActual = parseInt(moment().format("x"));

      const dataCodigoVerificacion = await dispatch(
        "consultarCodigoCorreo",
        identificadorDB
      );

      const codigoVerificacion = dataCodigoVerificacion?.codigo;
      const vigenciaCodigo = dataCodigoVerificacion?.vigencia;

      console.log(timestampActual);
      console.log(codigoVerificacion);
      console.log(vigenciaCodigo);

      if (!dataCodigoVerificacion) {
        throw {
          msg: "Éste código que ingresaste no coincide con el de la base de datos"
        }
      }

      if (codigoVerificacion == codigoUsuario) {
        if (timestampActual <= vigenciaCodigo) {
          return true;
        } else {
          throw {
            msg: "Éste código ha caducado"
          }
        }
      } else {
        throw {
          msg: "Éste código que ingresaste no coincide con el de la base de datos"
        }
      }

      console.log(dataCodigoVerificacion);
    } catch (error) {
      throw error
    }
  },

  async enviarCodigoTwilio({ commit }, datosEnvioTelefono) {
    try {
      console.log(datosEnvioTelefono);
      const {
        metodo,
        telefono
      } = datosEnvioTelefono;
      console.log(metodo);
      const res = await apiValidacion({
        method: "POST",
        url: "/enviar-codigo-twilio",
        data: {
          phoneNumber: telefono, 
          channel: metodo
        },
      });
      console.log(res);
      if (res.status == 200) {
        return res.data;
      }
    } catch (error) {
      console.log(error);
      let msgError = {};
      if (error.message == "Network Error") {
        msgError = { msg: "Error de red" };
      } else if (error.response) {
        msgError = error.response.data.errors[0];
      } else {
        msgError = { msg: "Error inesperado, intentelo más tarde" };
      }
      throw msgError;
    }
  },

  async validarCodigoTwilio({ commit }, datosEnvioTelefono) {
    try {
      console.log(datosEnvioTelefono);
      const {
        codigo,
        telefono
      } = datosEnvioTelefono;
      const res = await apiValidacion({
        method: "POST",
        url: "/validar-codigo-twilio",
        data: {
          phoneNumber: telefono, 
          codenumber: codigo
        },
      });
      console.log(res);
      return res.data.verificationCode.status=='approved' ? true : false;
    } catch (error) {
      console.log(error);
      let msgError = {};
      if (error.message == "Network Error") {
        msgError = { msg: "Error de red" };
      } else if (error.response) {
        msgError = error.response.data.errors[0];
      } else {
        msgError = { msg: "Error inesperado, intentelo más tarde" };
      }
      throw msgError;
    }
  },
  async guardarNotaPreregistro({ commit }, objetoDataPreregistro) {
    try {
      const { preregistroId, nota } = objetoDataPreregistro;
      const db = getDatabase();
      const updates = {};
      const urlBase = `/projects/proj_tCJWQHSHNf7WoMu7r64pUJ/data/preregistroTarjetahabientes`;

      updates[`${urlBase}/${preregistroId}/nota`] = nota;

      console.log(updates);

      const res = await update(ref(db), updates);
    } catch (error) {
      throw {
        msg: "Error al guardar nota pre registro firebase",
        code: "SKE-130",
      };
    }
  },

  async editarTarjetahabienteHasuraManual(
    { commit },
    dataTarjetahabienteHasura
  ) {
    try {
      const {
        Record_Id,
        nombre,
        apellidoM,
        apellidoP,
        telefono,
        email,
        cp,
        fechanacim,
        sexo,
        fechaalta,
        division,
        promotor,
        fechaverfi,
        verificoemail,
        dioalta,
      } = dataTarjetahabienteHasura;
      console.log(dataTarjetahabienteHasura);
      const res = await apiValidacion({
        method: "POST",
        url: "/editar-tarjetahabiente",
        data: {
          Record_Id,
          nombre,
          apellidoM,
          apellidoP,
          telefono,
          email,
          cp,
          fechanacim,
          sexo,
          fechaalta,
          division,
          promotor,
          fechaverfi,
          verificoemail,
          dioalta,
        },
      });
      console.log(res);

      return true;
    } catch (error) {
      let msgError = {};
      if (error.message == "Network Error") {
        msgError = { msg: "Error de red" };
      } else if (error.response) {
        msgError = error.response.data.errors[0];
      } else {
        msgError = { msg: "Error inesperado, intentelo más tarde" };
      }
      throw msgError;
    }
  },

  async editarTarjetahabienteFirebaseManual(
    { commit },
    dataTarjetahabienteFirebase
  ) {
    try {
      const {
        Record_Id,
        nombre,
        apellidoM,
        apellidoP,
        telefono,
        email,
        cp,
        fechanacim,
        sexo,
        fechaaltat,
        division,
        promotor,
        fechaverif,
        verificoemail,
        dioalta,
      } = dataTarjetahabienteFirebase;
      const db = getDatabase();
      const updates = {};
      // /projects/proj_tCJWQHSHNf7WoMu7r64pUJ/data/tarjetahabientesVerificados/7100000117968
      const urlBase = `/projects/proj_tCJWQHSHNf7WoMu7r64pUJ/data/tarjetahabientesVerificados`;

      updates[`${urlBase}/${Record_Id}/nombre`] = nombre;
      updates[`${urlBase}/${Record_Id}/apellidoM`] = apellidoM;
      updates[`${urlBase}/${Record_Id}/apellidoP`] = apellidoP;
      updates[`${urlBase}/${Record_Id}/telefono`] = telefono;
      updates[`${urlBase}/${Record_Id}/email`] = email;
      updates[`${urlBase}/${Record_Id}/cp`] = cp;
      updates[`${urlBase}/${Record_Id}/fechanacim`] = fechanacim;
      updates[`${urlBase}/${Record_Id}/sexo`] = sexo;
      updates[`${urlBase}/${Record_Id}/fechaaltat`] = fechaaltat;
      updates[`${urlBase}/${Record_Id}/division`] = division;
      updates[`${urlBase}/${Record_Id}/promotor`] = promotor;
      updates[`${urlBase}/${Record_Id}/fechaverif`] = fechaverif;
      updates[`${urlBase}/${Record_Id}/verificoemail`] = verificoemail;
      updates[`${urlBase}/${Record_Id}/dioalta`] = dioalta;

      console.log(updates);

      const res = await update(ref(db), updates);
    } catch (error) {
      throw {
        msg: "Error al editar tarjetahabiente firebase",
        code: "SKE-132",
      };
    }
  },

  async consultarTarjetahabientesId({ commit }, tarjeta) {
    try {
      const formatoTarjeta = `%${tarjeta}%`;
      console.log(formatoTarjeta)
      const res = await apiValidacion({
        method: "POST",
        url: "/obtener-tarjetahabientes-id",
        data: {
          tarjeta: formatoTarjeta
        },
      });
      console.log(res);

      
      commit("setTarjetasVerificadasId", res.data.data.tarjetahabientesVerificados);
    } catch (error) {
      let msgError = {};
      if (error.message == "Network Error") {
        msgError = { msg: "Error de red" };
      } else if (error.response) {
        msgError = error.response.data.errors[0];
      } else {
        msgError = { msg: "Error inesperado, intentelo más tarde" };
      }
      throw msgError;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
